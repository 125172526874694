import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";
import ReactPlayer from "react-player";

const NoonieProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Noonie Australia.<br />
              Revolutionary Postpartum Care.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Entrepreneurs Alana Nolan and Winston Su ‘birthed’ Noonie Australia to improve the maternity experience for women by normalising postpartum recovery through their range of revolutionary postpartum essentials.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The idea for their revolutionary cooling pads was born when Founder Alana’s friend was preparing for the birth of her first baby girl and required functional and hygienic cooling maternity pads post-delivery. Alana was dissatisfied with the old-fashioned DIY "padsicles" but couldn't find any viable alternatives (after searching every corner of the web!).
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Refusing to let her friend use unhygienic and soggy padsicles, Alana got to work to create the first Noonie pad with integrated cooling built in.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Creating a world-first product can be a challenging task, and Alana and Winston struggled to find a suitable manufacturer to bring this revolutionary product to life - even going through another Australian sourcing company but with no success (they gave up after a few months).
            </p>
            <p className="font-bold text-xl lg:text-2xl">Enter Sourci - Here's how we made amazing happen for Noonie Australia.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/noonie-1.png"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>5+</span>
            </p>
            <p className="font-black text-xl">PROTOTYPING ROUNDS</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>{'>'}80</span>
            </p>
            <p className="font-black text-xl">MANUFACTURERS TENDERED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>300+</span>
            </p>
            <p className="font-black text-xl">EXPERT HOURS</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>1</span>
            </p>
            <p className="font-black text-xl">WINNING SOLUTION</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/noonie-2-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <p className="text-xl lg:text-2xl mb-8">
              After receiving Noonie’s world-first product brief, Sourci’s Co-Founder Ellie Vaisman actually flew up to Sydney to meet with Winston & Alana to learn about their goals. The Noonie product development had already been attempted by another Australian sourcing company that promised the world, but failed to deliver and pulled out of the project after just a few months due to complexity.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Changing the world isn’t easy, but it sure had Sourci’s commitment.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              It took nearly 100 manufacturers to find a suitable partner who would put the time, energy, and resources towards developing this world first product. There’s the cooling pouch itself with a customised density & formula, then there’s the soft cotton pad, and the part that presented the greatest challenge: Inserting the pouch into the pad using production machinery.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              No factory had the equipment to pair the cooling pouch with the sanitary pad, and many weren’t able to get it to work.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With the tireless efforts from Sourci’s domestic & overseas development teams, hundreds of hours spent meeting and problem solving with factories, not to mention the Noonie teams patience and determination, a working prototype was successfully made, and the code was finally cracked for mass production.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Utilising several partners for different stages of the production process, Sourci has brought the Noonie pad to life.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Giving total control and support, so those uncertainties just disappear.
            <span className="block text-sourci-pink">You got this.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How Sourci’s Expertise & Persistence Defied The Odds
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              After one of the most extensive research & product development undertakings ever encountered, Noonie Australia now have a rock-solid supply chain that will see them grow their impact to newfound mothers far and wide throughout the foreseeable future.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With the first production run of 50,000 organic, world first Noonie Padsicles successfully delivered in mid-2021, their brand is set to explode thanks to heavy interest from hospitals, pharmacies, government and mums-to-be all over the country.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Their goal is to have a sustainable and environmentally conscious business that helps as many mums as they can. Look out for further eco and functional iterations as we scale their product offering with them.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Changing the world is well worth the wait - so watch this space!
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/noonie-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="py-24 bg-[#ed76bc]">
        <div className="xl:container mx-auto px-6">
          <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-40 gap-x-60 w-full justify-center items-center">
            <div>
              <div className="mx-auto flex overflow-hidden rounded-3xl relative pt-[56.25%]">
                <ReactPlayer url="https://cdn.shopify.com/videos/c/vp/364249d7d01f4ddda0a33d0cbb8d00b1/364249d7d01f4ddda0a33d0cbb8d00b1.HD-720p-4.5Mbps.mp4"
                  controls
                  className="absolute top-0 left-0"
                  width="100%"
                  height="100%"
                  config={{
                    youtube: {
                        playerVars: {
                            modestbranding: 1,
                        },
                    },
                  }} />
              </div>
            </div>
            <div>
              <p className="text-white text-4xl lg:text-5xl font-bold">
              Genius.<br />
              Revolutionary.<br />
              Innovative.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Noonie" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="noonie"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default NoonieProject;

export function Head() {
  return (
    <Seo title="Noonie | Our Projects | Sourci"
      description="Entrepreneurs Alana Nolan and Winston Su ‘birthed’ Noonie Australia to improve the maternity experience for women by normalising postpartum recovery through their range of revolutionary postpartum essentials. Enter Sourci. Here’s how we made amazing happen for Noonie Australia."
      canonical="/projects/noonie" />
  )
}